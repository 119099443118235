<template>
  <Fieldset legend="จัดการข้อมูลผู้ใช้งาน" id="my-fieldset">
    <div class="p-d-flex p-grid">
      <div class="p-mb-2 p-lg-2 p-fluid">
        <Button
          label="เพิ่มข้อมูล"
          icon="pi pi-plus"
          class="p-button-primary p-button-sm p-mr-2 p-mb-2"
          @click="$router.push('/user/form')"
        />
      </div>
      <div class="p-mb-2 p-lg-3 p-fluid">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText
            v-model="dataFilters['global'].value"
            placeholder="ค้นหาข้อมูลผู้ใช้งาน"
          />
        </span>
      </div>
      <div class="p-mb-2 p-lg-3 p-fluid" v-if="userLevel == 'AC'">
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <Dropdown
            id="areaId"
            v-model="dataFilters['area.codeId'].value"
            :options="$store.state.area.areas"
            optionLabel="areaName"
            optionValue="codeId"
            placeholder="พื้นที่"
            :filter="true"
          />
        </span>
      </div>
    </div>

    <DataTable
      :value="users"
      ref="dt"
      dataKey="id"
      :paginator="true"
      :rows="20"
      v-model:filters="dataFilters"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      :rowsPerPageOptions="[20, 50, 100]"
      currentPageReportTemplate="แสดง {first} ถึง {last} จากข้อมูล {totalRecords}"
      class="p-datatable-gridlines p-datatable-striped p-datatable-responsive-demo"
    >
      <!-- ื่อ , นามสกุล , User_name, ระดับ User -->
      <Column
        field="no"
        header="ลำดับที่"
        :sortable="true"
        style="width: 8%; white-space: nowrap; text-align: center"
      ></Column>
      <Column
        field="fullName"
        header="ชื่อ - นามสกุล"
        :sortable="true"
      ></Column>
      <Column field="userName" header="Username" :sortable="true"></Column>
      <Column
        field="userLevelDesc"
        header="ระดับผู้ใช้งาน"
        :sortable="true"
      ></Column>
      <Column field="areaName" header="ชื่อพื้นที่" :sortable="true"></Column>
      <Column
        :exportable="false"
        header="จัดการ"
        style="width: 10%; white-space: nowrap"
      >
        <template #body="slotProps">
          <div v-if="slotProps.data.codeId != user?.codeId">
            <Button
              icon="pi pi-pencil"
              class="p-button-sm p-button-success p-mr-2"
              @click="editUser(slotProps.data)"
            />
            <Button
              icon="pi pi-trash"
              class="p-button-sm p-button-danger"
              @click="() => confirmDeleteUserDialog(slotProps.data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </Fieldset>
  <!-- Delete Dialog -->
  <ConfirmDelete @onConfirmDeleted="confirmDeleted" />
  <!--/ Delete Dialog -->
</template>
<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import ConfirmDelete from '@/components/dialogs/ConfirmDelete'
export default {
  name: 'datatable',
  layout: 'Private',
  data() {
    return {
      entity: null,
      dataFilters: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        },
        'area.codeId': {
          value: null,
          matchMode: FilterMatchMode.EQUALS
        }
      }
    }
  },
  async created() {
    try {
      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea
      } = this.$store.getters['oauth/session']
      const payload = {
        userLevel: userLevel,
        areaId: userArea?.codeId
      }
      console.log('payload ::== ', payload)
      await this.$store.dispatch('user/fetchUsersOrderFname', payload)
      await this.$store.dispatch('area/fetchAreasOrderNameTh')

      // save activity log
      this.$store.dispatch('activity/saveActionActivityLog', {
        actMenu: 'MANAGE_USER'
      })
    } catch (error) {
      //console.log('error ::==',error)
      this.$toast.add({
        severity: 'error',
        summary: 'เกิดข้อผิดพลาดจากระบบ',
        detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
        life: 3000
      })
    }
  },
  computed: {
    users() {
      const {
        codeId: userId,
        userLevel: userLevel,
        area: userArea
      } = this.$store.getters['oauth/session']
      console.log('userId ::==', userId)
      if (userId) {
        const { levels_ } = this.$store.state.constance
        console.log('userLevel ::==', userLevel)
        return this.$store.state.user.users.map((user, idx) => {
          const { area, userLevel, fname, lname } = user
          return {
            ...user,
            no: idx + 1,
            areaName: area?.areaName,
            userLevelDesc: levels_[userLevel],
            fullName: `${fname}   ${lname}`
          }
        })
      } else {
        return []
      }
    },
    levels() {
      const { codeId, userLevel: level } = this.$store.getters['oauth/session']
      if (codeId) {
        const { levels } = this.$store.state.constance
        return levels.filter((ul, i) => {
          const { code, name } = ul
          return level == 'AC' || (level == 'AL' && ['AL', 'OL'].includes(code))
        })
      } else {
        return []
      }
    },
    userLevel() {
      const { codeId, userLevel: level } = this.$store.getters['oauth/session']
      return level
    },
    user() {
      return this.$store.getters['oauth/session']
    }
  },
  methods: {
    confirmDeleteUserDialog(entity) {
      this.entity = entity
      this.$store.commit('dialog/setConfirmDelete', true)
    },
    async confirmDeleted() {
      try {
        console.log('this.elder ::==', this.entity)
        const { codeId: pkId } = this.entity
        await this.$store.dispatch('user/deleteUser', pkId)
        this.$store.commit('dialog/setConfirmDelete', false)
      } catch (error) {
        //console.log('error ::==',error)
        this.$toast.add({
          severity: 'error',
          summary: 'เกิดข้อผิดพลาดจากระบบ',
          detail: 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก',
          life: 3000
        })
      }
    },
    editUser(entry) {
      this.entry = entry
      const { codeId: pkId } = this.entry
      this.$router.push({
        name: 'UserFormId',
        params: { id: pkId }
      })
    }
  },
  components: {
    ConfirmDelete: ConfirmDelete
  }
}
</script>
<style lang="scss">
</style>
